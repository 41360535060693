



































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import VTaskList from "@/components/VTaskList.vue";
import { TasksTag } from "@/store";
import { url } from "@/api";

export default Vue.extend({
  components: { VTaskList },
  data() {
    return {
      keywords: "",
      tag_ids: [] as number[],
      activeTab: "",
      status: "",
      category: { id: undefined, name: "默认", name_en: "Default" },
      visible: false
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("app", {
      isCN: "isCN",
      language: "language",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapState("tasks", {
      categoryList: "categoryList",
      tagList: "tagList"
    }),
    ...mapGetters(["allow"]),
    tabs(): any {
      const tabs = [
        {
          id: "processing",
          label: this.$t("task.running"),
          status: "processing"
        },
        {
          id: "closed",
          label: this.$t("task.done"),
          status: "closed"
        },
        {
          id: "all",
          label: this.$t("task.all"),
          status: undefined
        }
      ];

      return tabs;
    }
  },
  methods: {
    goAdmin() {
      window.open(url.daoAdmin, "_blank");
    },
    onTagClick(tag: TasksTag) {
      const index = this.tag_ids.findIndex(id => id === tag.id);

      if (index === -1 && tag.id) {
        this.tag_ids.push(tag.id);
      } else {
        this.tag_ids.splice(index, 1);
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          tag_ids: this.tag_ids.toString()
        }
      });
    },
    onTabsChange(activeTab: string) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          active_tab: activeTab
        }
      });
    }
  },
  mounted() {
    const { active_tab, keywords, tag_ids } = this.$route.query;
    this.keywords = String(keywords || "");
    this.activeTab = String(active_tab || "processing");
    this.visible = true;
    if (tag_ids) {
      this.tag_ids = String(tag_ids)
        .split(",")
        .map(i => Number(i));
    }
  }
});
